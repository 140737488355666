<template>
    <section>
        <b-row>
            <b-col>
  
                <b-card border-variant="danger" v-if="errore_caricamento">
                    <b-row>
                    <b-col>
                        <h4 class="mt-0 mb-0 text-danger">Errore durante il caricamento delle informazioni iniziali</h4>
                        <p>Ti preghiamo di segnalare il problema all'assistenza tecnica, grazie</p>
                    </b-col>
                    <b-col 
                        md="4"
                        class="text-center"
                    >
                        <b-button
                        variant="primary"
                        :to="{ name: 'crm-utenti-index' }"
                        >
                            <feather-icon
                                icon="CornerUpLeftIcon"
                                size="16"
                                class="mr-1"
                            />Lista Utenti
                        </b-button>
                    </b-col>
                    </b-row>
                    
                </b-card>
                
                <div class="text-center my-3" v-if="Caricato == false">
                    <b-spinner
                        variant="primary"
                        style="width: 3rem; height: 3rem;"
                        class="mr-1"
                        label="loading"
                    />
                </div>
            
                <div v-if="Caricato">
                    <validation-observer ref="simpleRules">
                      <validation-provider
                      #default="{ errors }"
                      name="Firma email"
                      rules="required"
                      >
                        <quill-editor
                          v-model="firmaemail.firma"
                          :options="snowOption"
                        >
                          <div
                            id="toolbar"
                            slot="toolbar"
                          >
                            <button class="ql-bold">Bold</button>
                            <button class="ql-italic">Italic</button>
                            <button class="ql-underline">Underline</button>
                            <button class="ql-strike mr-2">Strike</button>

                            <select class="ql-header">
                              <option value="1" />
                              <option value="2" />
                              <option value="3" />
                              <option value="4" />
                              <option value="4" />
                              <option value="5" />
                              <option value="6" />
                              <option value="" selected />
                            </select>
                            <select class="ql-size mr-2">
                              <option value="small" />
                              <!-- Note a missing, thus falsy value, is used to reset to default -->
                              <option selected />
                              <option value="large" />
                              <option value="huge" />
                            </select>

                            <button class="ql-list" value="ordered">list ordered</button>
                            <button class="ql-list" value="bullet">list bullet</button>
                            <select class="ql-align mr-2" />

                            <button class="ql-link">Link</button>
                            <button class="ql-image mr-2">Image</button>
                          </div>

                        </quill-editor>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                      
                    </validation-observer>

                    <div class="mt-2">
                      <b-button
                        variant="primary"
                        type="submit"
                        :disabled="action_press"
                        @click.prevent="validationForm()"
                      >
                        <div v-if="action_press">Salvaggio in corso <b-spinner small class="ml-1" /></div>
                        <div v-else>Salva</div> 
                      </b-button>
                    </div>
                </div>

            </b-col>
        </b-row>
        
    </section>
  </template>

<script>
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import it from 'vee-validate/dist/locale/it.json'
import router from '@/router'
import { BTabs, BTab, BRow, BCol, BCard, BCardText, BSpinner, BButton, BFormGroup, BForm, BInputGroup, BFormInput, BInputGroupPrepend, BInputGroupAppend, BDropdownDivider, BFormSelect } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  required, length,
} from '@validations'
import { quillEditor } from 'vue-quill-editor'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    it,
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard, 
    BCardText, 
    BSpinner, 
    BButton, 
    BForm,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BDropdownDivider,
    BFormSelect,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,

    quillEditor,

  },
  data() {
    return {
      Caricato: false,
      errore_caricamento: false,

      required, 
      length,
      firmaemail: {
        firma: '',
        user_id: '',
      },
      submitted: false,
      action_press: false,
      url_api_salvataggio_firma_email: '',
      
      snowOption: {
        placeholder: 'Inserisci la firma ...',
        modules: {
          /*
            toolbar: [
                ['bold', 'italic', 'underline', 'strike', 'link'],        // toggled buttons
                //[{ 'header': 1 }, { 'header': 2 }],               // custom button values
                [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                //[{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
                //[{ 'direction': 'rtl' }],                         // text direction

                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown

                [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                //[{ 'font': [] }],
                [{ 'align': [] }],

                ['image'],

                ['blockquote', 'code-block'],
                [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent

                ['clean']                                         // remove formatting button
            ]
          */
          toolbar: '#toolbar',
        },
        theme: 'snow',
      },

    }
  },
  created() {
    //errori validazione in italiano
    localize({
      it: {
        messages: it.messages,
      },
    })

    const infoUserPromise = this.$http.get('api/crm/employee/card/'+router.currentRoute.params.id_riga)

    Promise.all([infoUserPromise]).then(response => {        
        if(response[0].data.statusCode===200){
          //dati user (partendo dalla tabella Employee)
          this.campiform = response[0].data.reply.employeeData

          //per auto-associare la firma all'User_id corretto (ricevuto dall'api che parte dei dati dell'Employee)
          this.firmaemail.user_id = response[0].data.reply.employeeData.user_id

            this.$http.get('api/crm/emailsignature/emailuser/'+response[0].data.reply.employeeData.user_id).then(emailsignature => {
              if(emailsignature.data.statusCode===200){
                console.log(emailsignature.data.reply.data)

                if(emailsignature.data.reply.data === null){
                  //firma non presente -> va fatto il primo inserimento
                  this.url_api_salvataggio_firma_email = 'api/crm/emailsignature/card' //new in salvataggio
                } else {
                  //firma trovata
                  this.firmaemail.firma = emailsignature.data.reply.data.firma
                  this.firmaemail.id = emailsignature.data.reply.data.id

                  this.url_api_salvataggio_firma_email = 'api/crm/emailsignature/card/'+this.firmaemail.id //update in salvataggio
                }
              } else {
                this.$swal({
                  icon: 'warning',
                  title: 'Errore USF 07',
                  text: 'Non è stato possibile recuperare i dati della firma, prego assisare il nostro reparto tecnico',
                  customClass: {
                    confirmButton: 'btn btn-outline-primary',
                  },
                })
              }
            }).catch(e => {
              console.log(e);
            });

          //fine
          this.Caricato = true;
          this.errore_caricamento = false;

        } else {
          this.Caricato = false;
          this.errore_caricamento = true;
        }
    })
    
  },
  methods: {
    validationForm() {
      //alert('inizio processio di salvataggio ... da fare\n\n'+this.url_api_salvataggio_firma_email);
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          //console.log('inizio processio di salvataggio ... da fare\n\n'+this.url_api_salvataggio_firma_email);
          //console.log(this.firmaemail);

          this.action_press = true
          
          this.$http.post(this.url_api_salvataggio_firma_email, 
            this.firmaemail
          ).then(response => { 
            //console.log("risposta server --------")
            //console.log(response.data)

            if((response.data.statusCode>=200) && (response.data.statusCode<=299)){
              //risposta positiva
              this.$router.replace('/crm/utenti')
                    .then(() => {
                        this.$swal({
                            icon: 'success',
                            title: response.data.reply.reply,
                            confirmButtonText: 'chiudi',
                            customClass: {
                            confirmButton: 'btn btn-success',
                            },
                        })
                    })

                this.action_press = false

            } else {
              //risposta negativa (errore sul server)
              this.$swal({
                icon: 'warning',
                title: 'Si è verificato un errore',
                text: 'La firma non è stata salvata correttamente',
                customClass: {
                  confirmButton: 'btn btn-outline-primary',
                },
              })

              this.action_press = false
            }
          }).catch(e => {
            console.log(e);
          });

        }
      })
    },

  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>
